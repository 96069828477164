import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from '../../Context/CartContext'
import { CurrencyContext } from '../../Context/CurrencyContext'
import { SessionContext } from '../../Context/SessionContext'
import { StoreContext } from '../../Context/StoreContext'
import { transformCategory } from '../../Utils/utils'

export default function MinimalProductDescription({
  id,
  nombreproducto,
  imagenproducto,
  precio,
  categoriaproducto,
  detalladoproducto,
  stock,
  image,
  extras,
  variables,
  changeImage,
  estilo,
}) {
  const { store, setStore } = useContext(StoreContext)
  const { cart, setCart } = useContext(CartContext)
  const { session, setSession } = useContext(SessionContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const [alert, setAlert] = React.useState(false)

  function AppendToCart(id, name, image, precio) {
    let body = {
      id: id,
      nombreproducto: name,
      imagenproducto: image,
      precio: precio,
    }
    let copyProducts = [...session]
    copyProducts.push(body)
    localStorage.setItem('session', JSON.stringify(copyProducts))
    setSession(copyProducts)
    setCart(true)
  }

  return (
    <div>
      <div className='grid grid-cols-1 gap-2 p-4'>
        <div className='text-4xl font-semibold tracking-tighter capitalize'>
          {nombreproducto}
        </div>
        <Link
          to={`/category/${id}`}
          className='text-sm font-semibold tracking-tighter'
        >
          {transformCategory(categoriaproducto)}
        </Link>
        <div className='text-sm font-light'>{detalladoproducto}</div>
      </div>
      <div className='grid grid-cols-1 gap-4 px-8 py-8 rounded-b-xl'>
        <div className='grid grid-cols-1 '>
          <div className='grid justify-start grid-cols-3 gap-2 mb-6'>
            {image &&
              image.map((image, index) => (
                <div
                  key={index}
                  onClick={() => changeImage(image)}
                  style={{ backgroundImage: `url(${image.url})` }}
                  className='flex flex-shrink-0 w-full h-24 bg-center bg-cover border border-gray-200 cursor-pointer aspect-1'
                ></div>
              ))}
          </div>
          <div className='flex justify-between'>
            {variables &&
              variables.map((variable, index) => (
                <div
                  key={index}
                  className='text-sm font-semibold tracking-tighter'
                >
                  {variable.nombre}
                </div>
              ))}
          </div>
          <div className='flex flex-row justify-between gap-2 '>
            <div className='opacity-50'>{stock} En stock</div>
            <div>{currency + ' '} {precio}</div>
          </div>
        </div>
        <div className='flex flex-col items-start justify-center'>
          <div
            className="px-4 py-2 text-lg font-bold  cursor-pointer bg-black text-white flex flex-row after:opacity-0 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex"
            onClick={() => {
              AppendToCart(id, nombreproducto, imagenproducto, precio)
            }}
            id={id}
          >
            Agregar al carrito
          </div>
          {alert ? (
            <div className='flex items-center gap-2 p-2 text-green-300'>
              <FontAwesomeIcon icon={faCheck} /> producto agregado
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
