import React, { useContext } from "react"
import { StoreContext } from "../Context/StoreContext"
import { LanguageContext } from "../Context/LanguageContext"
import MinimalContact from "../Components/Minimal/MinimalContact"
import DeliciousContact from "../Components/Delicious/DeliciousContact"
import ServiceContact from "../Components/Service/ServiceContact"

export default function GetContact({ data }) {
    const { store, setStore } = useContext(StoreContext)
    const language = useContext(LanguageContext)
    const textContact = language.text.contact

    if (store.theme === "minimal") { return (<MinimalContact data={store} textContact={textContact} />) }
    if (store.theme === "delicious") { return (<DeliciousContact data={store} textContact={textContact} />) }
    if (store.theme === "service") { return (<ServiceContact data={store} />) }
}