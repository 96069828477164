import React, { useContext, useState, useEffect } from 'react'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import './DeliciousSlider.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { StoreContext } from '../../Context/StoreContext'
import { rezizeCloudinary, transformCategory } from '../../Utils/utils'
import { CurrencyContext } from '../../Context/CurrencyContext'

export default function DeliciousSlider({ data, banners }) {
  const { store, setStore } = useContext(StoreContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const [width, setWidth] = useState(0)
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setWidth])
  return (
    <div className=' w-full  md:p-0'>
      <div className='inline-block w-full my-2 overflow-hidden  h-auto'>
        <Slide className='p-0 m-0 -mr-5 from-rose-900 to-white p-4'>
          {banners && banners.length > 0 ? (
            banners.map((banner, i) => {
              return banner.urlbanner ? (
                <a
                  href={banner.urlbanner !== '' ? banner.urlbanner : '#'}
                  target='_blank'
                  key={i + 'b'}
                  className='flex flex-col items-center justify-center w-full overflow-hidden h-auto object-cover'
                >
                  <img
                    className='w-full h-auto md:h-full'
                    src={rezizeCloudinary(banner.link)}
                  />
                </a>
              ) : (
                <div
                  key={i + 'b'}
                  className='flex flex-col items-center justify-center w-full overflow-hidden h-auto '
                >
                  <img
                    className='w-full md:h-[460px]  h-[170px] ms:h-[180px]  mm:h-[190px] ml:h-[220px]   2xl:h-[650px] 	'
                    src={rezizeCloudinary(banner.link)}
                    alt={i}
                    // style={{
                    //   height: 450,
                    // }}
                  />
                </div>
              )
            })
          ) : (
            <div
              style={{
                background: 'gris',
              }}
              className={`flex flex-col bg-gray-200 p-6 w-full relative bg-center bg-cover h-128 `}
            >
              <div className='z-10 flex flex-col flex-grow'></div>

              <div className='absolute top-0 bottom-0 left-0 right-0 flex w-full h-full opacity-100 from-transparent bg-gradient-to-tl to-black'></div>
            </div>
          )}
        </Slide>
      </div>
    </div>
  )
}
