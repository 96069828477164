import React, { createContext, useState, useMemo, useEffect } from "react";

const SessionContext = createContext({
    session: [],
    setSession: () => { 
    },
});

const SessionContextProvider = ({ children }) => {
    
    const [session, setSession] = useState([]);
    useEffect(()=>{
        let newsession = JSON.parse(localStorage.getItem('session'))
        setSession(newsession?newsession:[]);
    },[])
    const value = useMemo(() => ({ session, setSession }), [session]);

    return (
        <SessionContext.Provider value={value}>
            {children}
        </SessionContext.Provider>
    );
};

const SessionContextConsumer = SessionContext.Consumer;
export { SessionContext, SessionContextProvider, SessionContextConsumer };