import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from '../../Context/CartContext'
import { SessionContext } from '../../Context/SessionContext'
import { StoreContext } from '../../Context/StoreContext'
import { CurrencyContext } from '../../Context/CurrencyContext'
import { transformCategory } from '../../Utils/utils'
import AmountButtons from '../AmountButtons'

export default function DeliciousProductDescription({
  id,
  nombreproducto,
  imagenproducto,
  precio,
  categoriaproducto,
  productvariations,
  detalladoproducto,
  stock,
  image,
  extras,
  variations,
  changeImage,
  codigoproducto,
  isproductstock,
  categorias,
}) {
  const { store, setStore } = useContext(StoreContext)
  const { cart, setCart } = useContext(CartContext)
  const { session, setSession } = useContext(SessionContext)
  const [alert, setAlert] = useState(false)
  const [variation, seVariation] = useState({})
  const [prueba, setPrueba] = useState({})
  const valuesDefault = {}
  const [amount, setAmount] = useState(1)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  // esto descomentar

  // LOGICA DE INCREMENT  DECREASE

  const increase = () => {
    setAmount((oldAmount) => {
      let tempAmount = Number(oldAmount) + 1
      if (signalprice('isStockIlimit') === true && signalprice('stock') == '') {
      } else if (signalprice('stock') === '' && isproductstock) {
      } else if (signalprice('stock') == 0) {
        tempAmount = 1
      } else if (tempAmount > signalprice('stock')) {
        tempAmount = signalprice('stock')
      }

      return tempAmount
    })
  }
  const decrease = () => {
    setAmount((oldAmount) => {
      let tempAmount = oldAmount - 1
      if (tempAmount < 1) {
        tempAmount = 1
      }
      return tempAmount
    })
  }

  useEffect(() => {
    variations &&
      variations?.forEach((p, i) => {
        valuesDefault[p.name] = p.values[0]
      })

    seVariation(valuesDefault)
  }, [])

  const [extrasInput, setExtrasInput] = useState(
    new Array(extras ? extras.length : []).fill(false)
  )
  const [totalExtra, setTotalExtra] = useState(0)
  const [dataExtras, setDataExtras] = useState([])

  let changeExtras = (i) => {
    const updatedCheckedState = extrasInput.map((item, index) =>
      index === i ? !item : item
    )
    setExtrasInput(updatedCheckedState)
    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + Number(extras[index].price)
        }
        return sum
      },
      0
    )
    let selectExtras = []
    updatedCheckedState.map((el, i) => {
      if (el === true) {
        selectExtras.push(extras[i])
      }
    })
    setDataExtras(selectExtras)
    setTotalExtra(totalPrice)
  }

  //   function AppendToCart(id, name, image, precio, codigoproducto) {
  //     let totalList = variation !== null ? variation.price : precio
  //     let body = {
  //       id: id,
  //       nombreproducto: name,
  //       imagenproducto: image,
  //       precio: Number(totalList) + Number(totalExtra),
  //       codigoproducto: codigoproducto,
  //       variation: variation,
  //       extras: dataExtras,
  //     }

  //     let copyProducts = [...session]
  //     copyProducts.push(body)
  //     localStorage.setItem('session', JSON.stringify(copyProducts))
  //     setSession(copyProducts)

  //     setCart(true)
  //   }

  function AppendToCart(
    id,
    name,
    image,
    precio,
    codigoproducto,
    max,
    amount,
    isproductstock,
    categorias
  ) {
    let extrasContent = ''
    dataExtras?.map((ex) => {
      extrasContent += ex.name
    })

    const idProduct = id + Object.values(variation).join('') + extrasContent
    const idProduct1 = id + Object.values(variation).join('')

    // let totalList = variations !== null ? variation.price : precio
    let totalList = variations.length > 0 ? prueba.precio : precio
    const tempItem = session.find((i) => i.id1 === idProduct)
    if (tempItem) {
      const tempCart = session?.map((cartItem) => {
        if (cartItem.id1 === idProduct && cartItem.id2 === idProduct1) {
          const tempItem1 = session.filter(
            (i) => i.id2 === idProduct1 && i.id1 !== idProduct
          )
          let amountExtras = 0
          tempItem1.forEach((element) => {
            amountExtras += element.amount
          })

          const tempTODO = session.filter((i) => i.id2 === idProduct1)

          let amountExtrasTodo = 0

          tempTODO.forEach((element) => {
            amountExtrasTodo += element.amount
          })
          let newAmount = cartItem.amount + amount

          console.log('sinMiedo', signalprice('isStockIlimit'))
          console.log('sinMiedo', signalprice('stock'))

          if (newAmount + amountExtras > cartItem.max && cartItem.max != '') {
            newAmount = cartItem.max - amountExtrasTodo + cartItem.amount
            // newAmount = cartItem.amount
          } else if (
            signalprice('isStockIlimit') === true &&
            signalprice('stock') == ''
          ) {
            console.log('aquii55')

            newAmount = cartItem.amount + amount
          } else if (!isproductstock && cartItem.max == '') {
            console.log('aquii77')

            newAmount = cartItem.amount + amount
          } else if (newAmount > cartItem.max) {
            console.log('aquii88')

            newAmount = cartItem.max
          }

          return { ...cartItem, amount: newAmount }
        } else {
          return cartItem
        }
      })
      setSession(tempCart)

      localStorage.setItem('session', JSON.stringify(tempCart))
    } else {
      console.log('pronando2')

      const tempTODO = session.filter((i) => i.id2 === idProduct1)
      let amountExtrasTodo = 0
      tempTODO.forEach((element) => {
        amountExtrasTodo += element.amount
      })

      let body = {
        id: id,
        id1: idProduct,
        id2: idProduct1,
        nombreproducto: name,
        imagenproducto: image,
        precio: Number(totalList) + Number(totalExtra),
        codigoproducto: codigoproducto,
        variation: variation,
        extras: dataExtras,
        amount: parseInt(amount),
        max: parseInt(max),
        categorias,
      }
      if (extras.length >= 1) {
        // if (max !== amountExtrasTodo && isproductstock) {

        if (
          signalprice('isStockIlimit') === true &&
          signalprice('stock') == ''
        ) {
        } else if (
          max <= amountExtrasTodo + parseInt(amount) &&
          isproductstock
        ) {
          body.amount = parseInt(max) - parseInt(amountExtrasTodo)

          if (max == amountExtrasTodo) {
            return
          }
        }

        // body.amount = max - amountExtrasTodo
      }
      let copyProducts = [...session]
      copyProducts.push(body)
      setSession(copyProducts)
      localStorage.setItem('session', JSON.stringify(copyProducts))
    }

    setCart(true)
  }

  function changeVariation(event) {
    let newdatainfo = variations[event.target.value]
    if (event.target.value === 'null') {
      newdatainfo = null
    }
    seVariation(newdatainfo)
  }

  const updateVariations = (e) => {
    let name = e.target.name

    setAmount(1)

    seVariation({ ...variation, [name]: e.target.textContent })
  }

  function signalprice(name) {
    let result
    let rstock
    let isStockIlimit

    if (variations && variations.length >= 1 && variations !== null) {
      productvariations &&
        productvariations.map((pVa) => {
          if (JSON.stringify(pVa.options) === JSON.stringify(variation)) {
            result = pVa.precio
            rstock = pVa.stock
            isStockIlimit = pVa.isStockIlimit
            prueba.isStockIlimit = pVa.isStockIlimit
            prueba.precio = pVa.precio
            prueba.stock = pVa.stock
          }
        })
    } else {
      result = precio
      rstock = stock
    }
    console.log('fff', isproductstock)
    if (name === 'isStockIlimit') {
      return isproductstock
    } else if (name === 'precio') {
      return Number(result) + Number(totalExtra)
    } else {
      return rstock
    }
  }
  return (
    // bg-white shadow-xl shadow-slate-800
    <div className='max-w-sm  rounded-xl '>
      <div className='grid grid-cols-1 gap-2 px-8 py-0 md:py-8'>
        <div className='text-4xl font-semibold tracking-tighter'>
          {nombreproducto}
        </div>
        {variations.length === 0 && (
          <div className='font-bold text-2xl'>
            {currency + ' ' + signalprice('precio')}
          </div>
        )}
        {variations.length >= 1 && (
          <div className='font-bold'>
            {currency + ' ' + signalprice('precio')}
          </div>
        )}
        <div className='text-sm font-light whitespace-pre-line'>
          {detalladoproducto}
        </div>
        {variations && variations.length > 0 ? (
          <>
            <div className='text-xs font-semibold tracking-tighter'>
              Variaciones
            </div>
            {/* <select
              onChange={(e) => changeVariation(e)}
              className='w-full p-2 border border-gray-400 rounded-sm'
            >
              {variations &&
                variations.map((variation, i) => {
                  return (
                    <option key={i} value={i}>
                      {variation.name}
                    </option>
                  )
                })}
            </select> */}
            {variations &&
              variations.map((vari, i) => {
                return (
                  <div key={i}>
                    <p>{vari?.name}</p>
                    <div className='flex gap-3'>
                      {vari.values.map((p, i) => {
                        return (
                          <button
                            className={`${
                              variation[vari.name] === p
                                ? 'cursor-pointer rounded-sm p-3 bg-powerbizz'
                                : 'cursor-pointer rounded-sm p-3 bg-slate-200'
                            }`}
                            key={i}
                            onClick={(e) => {
                              updateVariations(e, vari)
                            }}
                            name={vari?.name}
                            type='button'
                          >
                            {p}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
          </>
        ) : (
          ''
        )}
        <div className='flex gap-4'>
          <p>Cantidad:</p>
          <AmountButtons
            amount={amount}
            increase={increase}
            decrease={decrease}
          />
        </div>
      </div>
      <div className='grid grid-cols-1 gap-4 px-8 py-8  rounded-b-xl'>
        {extras && extras.length > 0 ? (
          <>
            <div className='font-semibold '>Extras</div>
            <div className='flex flex-col'>
              {extras &&
                extras.map((extra, i) => {
                  return (
                    <div key={i} className='flex flex-row justify-between '>
                      <label>
                        <input
                          className='mr-1'
                          type='checkbox'
                          id={i}
                          // name={name}
                          // value={name}
                          // checked={checkedState[index]}
                          onChange={() => changeExtras(i)}
                        />
                        {extra.name}
                      </label>
                      <div className=''>+ {currency + ' ' + extra.price}</div>
                    </div>
                  )
                })}
            </div>
          </>
        ) : (
          ''
        )}

        <div className='grid grid-cols-1 '>
          {variations.length === 0 && (
            <div className='flex flex-row justify-between gap-2 '>
              <div className='opacity-50'>
                {signalprice('stock') == ''
                  ? signalprice('stock')
                  : signalprice('stock') == 0
                  ? 'No disponible'
                  : `Solo quedan ${signalprice('stock')}`}
              </div>
              <div className='text-xl'>
                {currency + ' ' + signalprice('precio')}
              </div>
            </div>
          )}

          {variations.length >= 1 && (
            <div className='flex flex-row justify-between gap-2 '>
              <div className='opacity-50'>
                {signalprice('stock') == ''
                  ? signalprice('stock')
                  : signalprice('stock') == 0
                  ? 'No disponible'
                  : `Solo quedan ${signalprice('stock')}`}
              </div>
            </div>
          )}
        </div>
        <div className='flex flex-col items-center justify-center'>
          {signalprice('stock') === '' ? (
            <div
              className="px-4 py-2 text-lg font-bold rounded-md w-full  cursor-pointer bg-powerbizz flex flex-row after:opacity-0 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex text-white justify-center"
              onClick={() => {
                AppendToCart(
                  id,
                  nombreproducto,
                  imagenproducto,
                  precio,
                  codigoproducto,
                  signalprice('stock'),
                  amount,
                  isproductstock,
                  categorias
                )
              }}
              id={id}
            >
              Agregar al carrito
            </div>
          ) : isproductstock && signalprice('stock') == 0 ? (
            <div
              // style={{
              //   backgroundColor: store.primarycolor
              //     ? store.primarycolor
              //     : 'inherit',
              //   color: store.primarycolor ? '#fff' : 'inherit',
              // }}
              className="px-4 py-2 text-lg font-bold rounded-md w-full  cursor-pointer bg-powerbizz flex flex-row after:opacity-0 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex text-white justify-center"
            >
              Sin Stock
            </div>
          ) : (
            <div
              // style={{
              //   backgroundColor: store.primarycolor
              //     ? store.primarycolor
              //     : 'inherit',
              //   color: store.primarycolor ? '#fff' : 'inherit',
              // }}
              className="px-4 py-2 text-lg font-bold rounded-md w-full  cursor-pointer bg-powerbizz flex flex-row after:opacity-0 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex text-white justify-center"
              onClick={() => {
                AppendToCart(
                  id,
                  nombreproducto,
                  imagenproducto,
                  precio,
                  codigoproducto,
                  signalprice('stock'),
                  amount,
                  isproductstock,
                  categorias
                )
              }}
              id={id}
            >
              Agregar al carrito
            </div>
          )}

          {alert ? (
            <div className='flex items-center gap-2 p-2 text-green-300'>
              <FontAwesomeIcon icon={faCheck} /> producto agregado
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
