import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export default function MinimalCategoryGrid({ data }) {
    return (
        <>
            <div className='container p-4 pt-24'>
                <div className='flex flex-col items-start gap-4 pt-12 my-8'>
                    <Link className="flex items-center gap-2" to={`/`}>
                        <FontAwesomeIcon className='text-xxs' icon={faArrowLeft} /> regresar a home
                    </Link>
                    <div className='w-full pb-2 font-serif text-4xl font-bold tracking-tighter text-gray-700 border-b border-gray-300 '>
                        Productos
                    </div>
                    <div>{data.length} Categorias</div>
                </div>
            </div>
            <div className='container p-4 '>
                <div className='grid grid-cols-1 gap-6 pt-3 mb-6 md:grid-cols-2'>
                    {data.map((category, index) => {
                        return (
                            <div key={index} className="flex flex-col gap-2">
                                <Link to={`/category/${category.id}`} style={{ backgroundImage: `url(${category.productos[0].image[0].url})` }} className="hover:-translate-y-1 hover:shadow-[-5px_5px_0px_0px_rgba(200,200,200,1)] duration-700 shadow relative flex items-end justify-end p-4 overflow-hidden transition-all bg-center bg-cover h-128" >
                                </Link>
                                <Link to={`/category/${category.id}`}
                                    className="items-start justify-start flex-grow w-3/5 pt-1 font-serif text-lg font-bold leading-5 tracking-tighter text-black capitalize transition-all duration-700 hover:text-black ">
                                    {category.nombrecategoria}
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}