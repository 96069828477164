import React, { useContext, set } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { CartContext } from '../../Context/CartContext'
import { SessionContext } from '../../Context/SessionContext'
import { CurrencyContext } from '../../Context/CurrencyContext'

export default function MinimalProductCard({
  imagenproducto,
  image,
  name,
  price,
  id,
  md,
  sd,
  xl,
  overlay,
  rounded,
  codigoproducto,
}) {
  const { cart, setCart } = useContext(CartContext)
  const { session, setSession } = useContext(SessionContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency

  function AppendToCart(id, name, image, price, codigoproducto) {
    let body = {
      id: id,
      nombreproducto: name,
      imagenproducto: imagenproducto,
      precio: price,
      codigoproducto: codigoproducto,
    }
    let copyProducts = [...session]
    copyProducts.push(body)
    localStorage.setItem('session', JSON.stringify(copyProducts))
    setSession(copyProducts)
    setCart(true)
  }

  if (md === true) {
    return (
      <div data-aos='fade-up' className='flex flex-col w-full gap-4'>
        {md ? null : null}
        <div
          style={{
            backgroundImage: `url(${
              imagenproducto !== ''
                ? imagenproducto
                : image && image.length > 0
                ? image[0].url
                : ''
            })`,
          }}
          className='hover:shadow-[-5px_5px_0px_0px_rgba(200,200,200,1)] shadow relative w-full duration-700 bg-center bg-cover no bg-no-repeat aspect-h-4 aspect-w-3 md:aspect-h-16 md:aspect-w-9 border ftransition-all  hover:-translate-y-1 hover:translate-x-1 hover:border-gray-300'
        >
          <a
            href={`/producto/${id}`}
            className={`absolute font-serif top-0 bottom-0 left-0 right-0`}
            alt={name}
          ></a>
        </div>
        <div className='flex flex-row flex-grow gap-2 py-2 font-serif '>
          <div className='flex flex-col w-full gap-2'>
            <a
              href={`/producto/${id}`}
              className='items-start justify-start flex-grow w-3/5 text-lg leading-5 tracking-tighter text-gray-500 capitalize transition-all duration-700 hover:text-black '
            >
              {name}
            </a>
            <div>
              <span className='text-xs'> {currency}</span>
              {Number(price).toFixed(2)}
            </div>
          </div>
          <div
            onClick={() => AppendToCart(id, name, image, price, codigoproducto)}
            className=" text-white self-start transition-all hover:bg-black bottom-2 cursor-pointer right-2 flex flex-row items-center after:text-sm after:opacity-0 delay-700 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex gap-1 py-1 px-2 text-lg font-bold  rounded-full bg-gray-300"
          >
            <FontAwesomeIcon icon={faAngleRight} className='text-xs' />
            <FontAwesomeIcon icon={faShoppingCart} className='text-sm' />
          </div>
        </div>
      </div>
    )
  }

  if (xl === true) {
    return (
      <div className='flex flex-col w-full gap-4 font-serif'>
        <div
          style={{
            backgroundImage: `url(${
              imagenproducto !== ''
                ? imagenproducto
                : image && image.length > 0
                ? image[0].url
                : ''
            })`,
          }}
          className={`hover:shadow-[-5px_5px_0px_0px_rgba(200,200,200,1)] duration-700 shadow relative flex items-end justify-end p-4 overflow-hidden transition-all bg-center bg-cover ${
            rounded ? 'rounded-xl' : null
          } hover:-translate-y-1  h-96`}
        >
          {/* <a href={`/producto/${id}`} className={`${overlay ? "bg-gradient-to-t from-slate-900 via-transparent to-transparent" : null}  absolute top-0 bottom-0 left-0 right-0`}></a> */}
        </div>
        <div className='flex flex-row'>
          <div className='flex flex-col w-full gap-2'>
            <a
              href={`/producto/${id}`}
              className='items-start justify-start flex-grow w-3/5 text-2xl leading-5 tracking-tighter text-gray-500 capitalize transition-all duration-700 hover:text-black '
            >
              {name}
            </a>
            <div>
              <span className='text-xs'> {currency}</span>
              {Number(price).toFixed(2)}
            </div>
          </div>
          <div
            onClick={() => AppendToCart(id, name, image, price, codigoproducto)}
            className=" text-white self-start transition-all hover:bg-black bottom-2 cursor-pointer right-2 flex flex-row items-center after:text-sm after:opacity-0 delay-700 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex gap-1 py-3 px-3 text-lg font-bold  rounded-full bg-gray-300"
          >
            <FontAwesomeIcon icon={faAngleRight} className='' />
            <FontAwesomeIcon icon={faShoppingCart} className='text-md' />
          </div>
        </div>
      </div>
    )
  }
}
