import React from 'react'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AmountButtons = ({ increase, decrease, amount }) => {
  return (
    <div className='flex gap-3'>
      <button type='button' className='cursor-pointer' onClick={decrease}>
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <h2 className=''>{amount}</h2>
      <button type='button' className='cursor-pointer' onClick={increase}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  )
}

export default AmountButtons
