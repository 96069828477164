import axios from 'axios'

export const GetFormTokenAPI = async (
  amount,
  currency,
  orderId,
  email,
  base64,
  endpoint
) => {
  try {
    const formattedPrice = amount.replace('.', '')

    const { data } = await axios.post(
      `https://vf-express-production.up.railway.app/formtoken`, // **NOTA PARA MARTIN ** aqui va el express endpoint, ideal en variable de entorno, para test "http://localhost:4000/formtoken"
      {
        amount: formattedPrice,
        currency: currency,
        orderId: orderId,
        customer: {
          email: email,
        },
        base64: base64,
      }
    )

    return data
  } catch (error) {
    console.log('fallo express', error)
  }
}
