// Definimos el reducer
const Couponreducer = (state, action) => {
  switch (action.type) {
    case 'GET_CUPONS_BEGIN':
      return { ...state, couponsLoading: true }
    case 'GET_CUPONS_SUCCESS':
      return { ...state, coupons: action.payload }
    case 'GET_PRODUCTS_ERROR':
      return { ...state, couponsLoading: true }
    case 'SET_VALID':
      return {
        ...state,
        valid: action.payload.valid,
        couponSelect: action.payload.discount,
      }
    case 'SET_COLLECTECD':
      return {
        ...state,
        collected: action.payload.collected,
      }
    case 'SET_PRICE':
      return {
        ...state,
        newTotal: action.payload.totall1,
      }
    case 'SET_ACCOUNT':
      return {
        ...state,
        isHomeDelivery: action.payload.isHomeDelivery,
        ispickupstore: action.payload.ispickupstore,
        isPricePerZone: action.payload.isPricePerZone,
        isOnlyPrices: action.payload.isOnlyPrices,
        isactivePlatform: action.payload.isactivePlatform,
        isactiveWsp: action.payload.isactiveWsp,
      }
    default:
      return state
  }
}

export default Couponreducer
