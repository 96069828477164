import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { CartContext } from '../../Context/CartContext'
import { SessionContext } from '../../Context/SessionContext'
import { StoreContext } from '../../Context/StoreContext'
import { CurrencyContext } from '../../Context/CurrencyContext'
import { rezizeCloudinary } from '../../Utils/utils'
import { useHistory } from 'react-router-dom'

export default function DeliciousProductCard({
  imagenproducto,
  description,
  image,
  name,
  price,
  stock,
  categorias,
  id,
  sm,
  md,
  xl,
  overlay,
  rounded,
  variations,
  isproductstock,
}) {
  const { store, setStore } = useContext(StoreContext)
  const { cart, setCart } = useContext(CartContext)
  const { session, setSession } = useContext(SessionContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const [width, setWidth] = useState(0)

  const history = useHistory()

  function AppendToCart(
    id,
    name,
    image,
    price,
    max,
    categorias,
    isproductstock
  ) {
    if (!variations) {
      // history.push(`/producto/${id}`)

      const tempItem = session.find((i) => i.id === id)

      if (tempItem) {
        const tempCart = session?.map((cartItem) => {
          if (cartItem.id === id) {
            let newAmount = cartItem.amount + 1

            if (max == '') {
            } else if (newAmount > cartItem.max) {
              newAmount = cartItem.max
            }

            return { ...cartItem, amount: newAmount }
          } else {
            return cartItem
          }
        })
        setSession(tempCart)
        localStorage.setItem('session', JSON.stringify(tempCart))
      } else {
        if (max === 0) {
          return
        }

        let copyProducts = [...session]
        let body = {
          id: id,
          id1: id,
          id2: id,
          nombreproducto: name,
          imagenproducto: rezizeCloudinary(imagenproducto),
          precio: price,
          amount: 1,
          categorias,
          max,
        }
        copyProducts.push(body)

        setSession(copyProducts)

        localStorage.setItem('session', JSON.stringify(copyProducts))
      }

      setCart(true)
    } else if (variations.length >= 1) {
      history.push(`/producto/${id}`)
    } else {
      const tempItem = session.find((i) => i.id === id)
      if (tempItem) {
        const tempCart = session?.map((cartItem) => {
          if (cartItem.id === id) {
            let newAmount = cartItem.amount + 1

            if (max == '') {
            } else if (newAmount > cartItem.max) {
              newAmount = cartItem.max
            }

            return { ...cartItem, amount: newAmount }
          } else {
            return cartItem
          }
        })
        setSession(tempCart)

        localStorage.setItem('session', JSON.stringify(tempCart))
      } else {
        if (max === 0) {
          return
        }

        const copyProducts1 = [...session]
        let body = {
          id: id,
          id1: id,
          id2: id,
          nombreproducto: name,
          imagenproducto: rezizeCloudinary(imagenproducto),
          precio: price,
          amount: 1,
          max,
          categorias,
        }
        copyProducts1.push(body)
        setSession(copyProducts1)
        localStorage.setItem('session', JSON.stringify(copyProducts1))
      }
      setCart(true)
    }
  }
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setWidth])
  let tranformText = (val) => {
    let newname = val.length > 28 ? val.substring(0, 25) + '...' : val
    return newname
  }
  if (sm === true) {
    return (
      <div className='grid grid-cols-7 gap-2 p-2 '>
        <div className='grid grid-cols-1 col-start-1 col-end-5'>
          <div className='mb-2 text-2xl font-bold'>{name}</div>
          <div className='mb-8 text-xs'>{description}</div>
          <div className='flex flex-row flex-grow gap-2 py-2 '>
            <a
              href={`/producto/${id}`}
              className='items-start justify-start flex-grow w-3/5 text-lg font-semibold leading-5 tracking-tighter capitalize hover:text-yellow-400'
            >
              {' '}
              {name}
            </a>
            <div
              onClick={() =>
                AppendToCart(
                  id,
                  name,
                  image,
                  price,
                  stock,
                  categorias,
                  isproductstock
                )
              }
              style={{
                backgroundColor: store.primarycolor
                  ? store.primarycolor
                  : 'inherit',
                color: store.primarycolor ? '#fff' : 'inherit',
              }}
              className="self-start bottom-2 cursor-pointer right-2 flex flex-row items-center after:text-sm after:opacity-0 delay-700 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex gap-1 px-1 pl-2 text-lg font-bold text-black rounded-full bg-primary"
            >
              <span className='text-xs'> </span>
              {Number(price).toFixed(2)}FFF
              <FontAwesomeIcon icon={faShoppingCart} className='text-sm' />
            </div>
          </div>
        </div>
        <div className='grid col-start-5 col-end-8 bg-orange-300 rounded-2xl'></div>
      </div>
    )
  }

  if (md === true) {
    return (
      <div className=' w-48  md:w-72 '>
        <div
          style={{
            backgroundImage: `url(${
              imagenproducto !== ''
                ? rezizeCloudinary(imagenproducto)
                : image && image.length > 0
                ? rezizeCloudinary(image[0].url)
                : ''
            })`,
          }}
          className='relative transition-all bg-center bg-cover rounded-xl hover:-translate-y-1 hover:scale-105 hover:shadow-xl h-48 w-48 md:h-72 md:w-72'
        >
          <a
            href={`/producto/${id}`}
            className={`absolute top-0 bottom-0 left-0 right-0`}
            alt={name}
          ></a>
        </div>
        <div className='  flex-grow gap-2 py-2 '>
          <a
            href={`/producto/${id}`}
            className='items-start justify-start flex-grow w-3/5 text-sm text-center  md:text-lg font-semibold leading-5 tracking-tighter capitalize hover:text-yellow-400'
          >
            {' '}
            {tranformText(name)}
          </a>
        </div>
        <div
          onClick={() =>
            AppendToCart(
              id,
              name,
              image,
              price,
              stock,
              categorias,
              isproductstock
            )
          }
          style={{
            backgroundColor: store.primarycolor
              ? store.primarycolor
              : 'inherit',
            color: store.primarycolor ? '#fff' : 'inherit',
          }}
          className="min-w-max mt-2 self-start bottom-2 cursor-pointer right-2 flex flex-row items-center after:text-sm after:opacity-0 delay-700 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex gap-1 px-1 pl-2 text-lg font-bold text-black rounded-full bg-primary w-1/2"
        >
          <span className='text-xs md:text-sm'> {currency}</span>
          <p className='text-[19px] md:text-[23px]'>
            {Number(price).toFixed(2)}
          </p>
          <FontAwesomeIcon icon={faShoppingCart} className='text-sm' />
        </div>
      </div>
    )
  }

  if (xl === true) {
    return (
      <div>
        <div
          style={{
            backgroundImage: `url(${
              imagenproducto !== ''
                ? rezizeCloudinary(imagenproducto)
                : image && image.length > 0
                ? rezizeCloudinary(image[0].url)
                : ''
            })`,
          }}
          className='relative transition-all bg-center bg-cover rounded-xl hover:-translate-y-1 hover:scale-105 hover:shadow-xl h-48 w-48 md:h-72 md:w-72'
        ></div>
        <div className='  flex-grow gap-2 py-2 '>
          <a
            href={`/producto/${id}`}
            className='items-start justify-start flex-grow w-3/5 text-sm text-center  md:text-lg font-semibold leading-5 tracking-tighter capitalize hover:text-yellow-400'
          >
            {' '}
            {tranformText(name)}
          </a>
        </div>
        <div
          onClick={() =>
            AppendToCart(
              id,
              name,
              image,
              price,
              stock,
              categorias,
              isproductstock
            )
          }
          style={{
            backgroundColor: store.primarycolor
              ? store.primarycolor
              : 'inherit',
            color: store.primarycolor ? '#fff' : 'inherit',
          }}
          className="mt-2 self-start bottom-2 cursor-pointer right-2 flex flex-row items-center after:text-sm after:opacity-0 delay-700 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex gap-1 px-1 pl-2 text-lg font-bold text-black rounded-full bg-primary w-1/2"
        >
          <span className='text-xs'> {currency}</span>
          <p className='text-xs md:text-lg'>{Number(price).toFixed(2)}</p>
          <FontAwesomeIcon icon={faShoppingCart} className='text-sm' />
        </div>
      </div>
    )
  }
}
