let regions = [
  { name: 'Amazonas', code: 'AMA' },
  { name: 'Áncash', code: 'ANC' },
  { name: 'Apurímac', code: 'APU' },
  { name: 'Arequipa', code: 'ARQ' },
  { name: 'Ayacucho', code: 'AYA' },
  { name: 'Cajamarca', code: 'CAJ' },
  { name: 'Callao', code: 'CALL' },
  { name: 'Cusco', code: 'CUS' },
  { name: 'Huancavelica', code: 'HCV' },
  { name: 'Huánuco', code: 'HNC' },
  { name: 'Ica', code: 'ICA' },
  { name: 'Junín', code: 'JUN' },
  { name: 'La Libertad', code: 'LLB' },
  { name: 'Lambayeque', code: 'LMB' },
  { name: 'Lima', code: 'LIM' },
  { name: 'Loreto', code: 'LOR' },
  { name: 'Madre de Dios', code: 'MDD' },
  { name: 'Moquegua', code: 'MOQ' },
  { name: 'Pasco', code: 'PAS' },
  { name: 'Piura', code: 'PIU' },
  { name: 'Puno', code: 'PUN' },
  { name: 'San Martín', code: 'SMT' },
  { name: 'Tacna', code: 'TAC' },
  { name: 'Tumbes', code: 'TUM' },
  { name: 'Ucayali', code: 'UCA' },
]

let productoSimulation = {
  istrending: false,
  _id: '63594ef214cdad00173bc435',
  imagenproducto:
    'https://res.cloudinary.com/ventafacil/image/upload/v1666797053/VentaFacil/lq6xgfxqihjjzwwj9fy3.jpg',
  nombreproducto: 'Resina',
  precio: '60',
  codigoproducto: '',
  detalladoproducto:
    'Resina\n🚨 importante 🚨\n📍 el precios NO incluye delivery\n📍 Trabajamos con el 100% de adelanto\n📍 agendamos con 24 horas de anticipación\n📍 entregas de última hora de coordina por whatsapp',
  image: [
    {
      url: 'https://res.cloudinary.com/ventafacil/image/upload/v1666797053/VentaFacil/lq6xgfxqihjjzwwj9fy3.jpg',
      public_id: 'VentaFacil/lq6xgfxqihjjzwwj9fy3',
    },
    {
      url: 'https://res.cloudinary.com/ventafacil/image/upload/v1666797085/VentaFacil/bwwgaefjzjul4fpabjp3.jpg',
      public_id: 'VentaFacil/bwwgaefjzjul4fpabjp3',
    },
  ],
  stock: '50',
  extras: [{ name: 'bandana', price: '30' }],
  variations: [
    {
      name: 'talla',
      type: 'product-option',
      values: [
        {
          label: 'M',
          name: 'M',
          type: 'product-option-value',
        },
        {
          label: 'L',
          name: 'L',
          type: 'product-option-value',
        },
        {
          label: 'S',
          name: 'S',
          type: 'product-option-value',
        },
      ],
    },
    {
      name: 'color',
      type: 'product-option',

      values: [
        {
          label: 'azul',
          name: 'azul',
          type: 'product-option-value',
        },
        {
          label: 'rojo',
          name: 'rojo',
          type: 'product-option',
        },
        {
          label: 'verde',
          name: 'verde',
          type: 'product-option',
        },
      ],
    },
    // { name: 'Diseño concha', price: '60' },
    // { name: 'Diseño huellita', price: '80' },
  ],

  published_at: '2022-10-26T15:14:58.030Z',
  createdAt: '2022-10-26T15:14:58.035Z',
  updatedAt: '2022-11-10T15:55:22.029Z',
  __v: 0,
  cliente: {
    clientstatus: 'pending',
    _id: '627a770b2c35fb0017422cdd',
    deliveryoptions: [
      { value: 'LM', label: 'La molina', price: '20' },
      { value: 'IND', label: 'Independencia', price: '20' },
      { value: 'LV', label: 'La victoria', price: '20' },
      { value: 'LIN', label: 'Lince', price: '20' },
      { value: 'AGU', label: 'El agustino', price: '20' },
      { value: 'LO', label: 'Los olivos', price: '20' },
      { value: 'MDM', label: 'Magdalena del mar', price: '20' },
      { value: 'ATE', label: 'Ate', price: '10' },
      { value: 'BAR', label: 'Barranco', price: '10' },
      { value: 'BRE', label: 'Breña', price: '10' },
      { value: 'CHA', label: 'Chaclacayo', price: '28' },
      { value: 'LUR', label: 'Lurín', price: '30' },
      { value: 'PH', label: 'Punta hermosa', price: '30' },
      { value: 'PN', label: 'Punta negra', price: '30' },
    ],
    correoelectronico: 'atencion.alcliente.izipay@gmail.com',
    Nombre: 'Silke',
    apelido: 'Huamani',
    region: 'Lima',
    ciudad: 'Lima',
    confirmed: true,
    nombrecomercio: 'silkeyanet',
    published_at: '2022-05-10T14:30:47.437Z',
    createdAt: '2022-05-10T14:30:35.148Z',
    updatedAt: '2022-11-11T13:01:15.357Z',
    __v: 0,
    users_permissions_role: '621e4da2f5027c0016586a5e',
    Ntorazonsocial: 'Sprint sales flowers',
    about: {
      info: 'CREANDO RECUERDOS INOLVIDABLES\n\nSomos los mejores regalitos, ofrecemos una amplia gama de productos y detalles personalizados.\nTe ayudamos a encontrar el detalle perfecto!\n\n',
      address: 'Gamarra',
      district: 'La victoria',
      timeatention: '7:00am -9pm',
      metapixelid: 'aaaa',
      Metapixelid: 'aaaaaa',
    },
    socialmedia: {
      facebook: 'https://www.facebook.com/',
      twitter: '',
      youtube: '',
      instagram: 'https://www.facebook.com/',
      whatsapp: '945391929',
    },
    abouts: {
      info: 'Montcafé es una empresa enfocada en hacer negocios conscientes que continúa la tradición cafetalera iniciada en los años 1990 en Rodríguez de Mendoza, en el departamento de Amazonas. Cultivamos y trabajamos directamente con productores de café, tostamos y servimos café peruano para nuestra comunidad de clientes y amigos.',
      address: 'Avenida los pinos 567',
      district: 'Jesús Maria',
      timeatention: 'Lun a Vier. 10a.m - 6p.m',
    },
    whatsapp: '945391929',
    primeraimagennosotros:
      'https://res.cloudinary.com/ventafacil/image/upload/v1667407987/VentaFacil/gana6ndle2ufewlp0kbb.jpg',
    segundaimagennosotros:
      'https://res.cloudinary.com/ventafacil/image/upload/v1652482247/VentaFacil-Manual%20Uploads/about-us-page-examples-1-61fd8f9784626-sej-1520x800_c3qjdq.jpg',
    terceraimagennosotros:
      'https://res.cloudinary.com/ventafacil/image/upload/v1652482247/VentaFacil-Manual%20Uploads/about-us-page-examples-1-61fd8f9784626-sej-1520x800_c3qjdq.jpg',
    theme: 'delicious',
    logo: 'https://res.cloudinary.com/ventafacil/image/upload/v1664079624/VentaFacil/igfsv9v1l30jc1bn3yts.jpg',
    primarycolor: '#ab0e0e',
    activeizi: true,
    currency: 'PEN',
    telefono: '999222993 / 4524893',
    direccionfiscal: 'Avenida los pinos 567',
    listbanners: [
      {
        urlbanner:
          'https://regalitos-silke.ventafacil.app/producto/632fe3b9edbf770017d21487',
        link: 'https://res.cloudinary.com/ventafacil/image/upload/v1666592426/VentaFacil/fw6rwsfwy3apdueklnv0.png',
        linkMobile: '',
        public_id: 'VentaFacil/fw6rwsfwy3apdueklnv0',
      },
      {
        urlbanner:
          'https://regalitos-silke.ventafacil.app/producto/635d964a14cdad00173bc4a3',
        link: 'https://res.cloudinary.com/ventafacil/image/upload/v1666796094/VentaFacil/isa9boxjtu6mk1lu1imn.png',
        linkMobile: '',
        public_id: 'VentaFacil/isa9boxjtu6mk1lu1imn',
      },
    ],
    doyouhaveavirtualstore: false,
    deliveryprecio: '',
    deliveryprovincia: [
      { label: 'Amazonas', value: 'Amazonas', price: '1' },
      { label: 'Apurímac', value: 'Apurímac', price: '12' },
      { label: 'Cajamarca', value: 'Cajamarca', price: '50' },
      { label: 'Cusco', value: 'Cusco', price: '50' },
      { label: 'Ica', value: 'Ica', price: '50' },
      { label: 'Callao', value: 'Callao', price: '50' },
      { label: 'Junín', value: 'Junín', price: '50' },
      { label: 'Ayacucho', value: 'Ayacucho', price: '50' },
      { label: 'Huánuco', value: 'Huánuco', price: '50' },
      { label: 'Puno', value: 'Puno', price: '50' },
      { label: 'San Martín', value: 'San Martín', price: '50' },
      { label: 'Tacna', value: 'Tacna', price: '50' },
      { label: 'Lima', value: 'Lima', price: '20' },
    ],
    preciodeliveryunico: false,
    paymentmethod: 'pending',
    deliveryprovince: true,
    metapixelid: '',
    GTM: '',
    urlpage: 'https://regalitos-silke.ventafacil.app/',
    tycstore: '636c8fc84043c40017b14214',
    id: '627a770b2c35fb0017422cdd',
  },
  categorias: [
    {
      productos: [
        '632fea2cedbf770017d21490',
        '632fea3fedbf770017d21491',
        '632fea76edbf770017d21493',
        '632feacfedbf770017d21495',
        '632feae7edbf770017d21496',
        '632fe9e4edbf770017d2148f',
        '63653e1cfda1500017199fa7',
        '62fe7a3cedbf770017d20d29',
        '636a5064fda1500017199fd0',
        '63594ef214cdad00173bc435',
        '632fea91edbf770017d21494',
      ],
      _id: '627b10232c35fb0017422d1c',
      nombrecategoria: 'Promociones ',
      published_at: '2022-05-11T01:23:47.811Z',
      createdAt: '2022-05-11T01:23:47.813Z',
      updatedAt: '2022-11-10T16:38:58.498Z',
      __v: 0,
      cliente: '627a770b2c35fb0017422cdd',
      thumbnail:
        'https://res.cloudinary.com/ventafacil/image/upload/v1652503867/VentaFacil-Manual%20Uploads/regalo-1_jahv30.jpg',
      isspecial: false,
      id: '627b10232c35fb0017422d1c',
    },
    {
      productos: [
        '63693c6efda1500017199fc8',
        '636c20bb4043c40017b14212',
        '62fe7a3cedbf770017d20d29',
        '636a5064fda1500017199fd0',
        '63594ef214cdad00173bc435',
        '632fe765edbf770017d2148c',
        '6362f3ebfda1500017199f9b',
        '632fea91edbf770017d21494',
      ],
      _id: '63501464c39d210017216ecd',
      nombrecategoria: 'Juguetes',
      thumbnail:
        'https://res.cloudinary.com/ventafacil/image/upload/v1666470189/VentaFacil/nh8ricrulvcpvan0q0jq.jpg',
      isspecial: false,
      published_at: '2022-10-19T15:14:44.163Z',
      createdAt: '2022-10-19T15:14:44.167Z',
      updatedAt: '2022-11-10T16:19:57.112Z',
      __v: 0,
      cliente: '627a770b2c35fb0017422cdd',
      id: '63501464c39d210017216ecd',
    },
  ],
  id: '63594ef214cdad00173bc435',
}

const metodoEntrega = [
  { value: true, label: 'Delivery' },
  { value: false, label: 'Recojo en Tienda' },
]
const metodoEntregaDelivery = [{ value: true, label: 'Delivery' }]
const metodoRecojo = [{ value: false, label: 'Recojo en Tienda' }]

const metodoPago = [
  { value: true, label: 'Pasarella' },
  { value: false, label: 'Whatsapp' },
]
const metodoPagoPlataforma = [{ value: true, label: 'Pasarella' }]
const metodoPagoWsp = [{ value: false, label: 'Whatsapp' }]

export {
  regions,
  metodoEntrega,
  metodoEntregaDelivery,
  metodoRecojo,
  metodoPago,
  metodoPagoPlataforma,
  metodoPagoWsp,
}
