import axios from 'axios'
import { getProductAPI } from './GetProduct'

export const discountStock = async () => {
  const stock = JSON.parse(localStorage.getItem('session'))
  // const cuponId = stock.id
  // const cuponStock = stock.amount

  stock.map((x) => {
    const productid = x.id
    let data

    getProductAPI(productid).then((res) => {
      if (x.variation === null || x.variation === undefined) {
        if (x.max === '') {
          return x
        }

        data = {
          stock: (x.max - x.amount).toString(),
        }

        let config = {
          method: 'put',
          url: `https://ventafazil-strapi-production.up.railway.app/productos/${productid}`,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
          },
          data,
        }

        axios(config)
      } else if (Object.entries(x.variation).length === 0) {
        if (x.max === '') {
          return x
        }

        data = {
          stock: (x.max - x.amount).toString(),
        }

        let config = {
          method: 'put',
          url: `https://ventafazil-strapi-production.up.railway.app/productos/${productid}`,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
          },
          data,
        }

        axios(config)
      } else {
        const resp = res.productvariations.map((item, index) => {
          if (item.stock === '') {
            return item
          }

          if (JSON.stringify(item.options) === JSON.stringify(x.variation)) {
            let newStock = x.max - x.amount

            return { ...item, stock: newStock }
          } else {
            return item
          }
        })

        data = {
          productvariations: resp,
        }
        let config = {
          method: 'put',
          url: `https://ventafazil-strapi-production.up.railway.app/productos/${productid}`,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
          },
          data,
        }

        axios(config)
      }
    })
  })
}
