import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../Context/StoreContext";
import ServiceProductsList from "./ServiceProductsList";

export default function ServiceCategoriesList({ data }) {
    const { store, setStore } = useContext(StoreContext)
    const [home, setHome] = useState(false);

    useEffect(() => { if (window.location.pathname === "/") { setHome(true); } }, [home])

    return (
        <div className="relative flex">
            <div className="container flex flex-row md:gap-12" >
                <div className="w-full md:flex">
                    {data.map((category, index) => {
                        return (
                            <ServiceProductsList key={index} products={category.productos} id={category._id} name={category.nombrecategoria} />
                        );
                    })}
                </div>
                {home && <div className="flex w-full"></div>}
            </div>
            {home && <div className="absolute right-0 flex w-1/2 h-full max-h-screen bg-red-500 bg-cover" style={{ backgroundImage: `url(${store.primeraimagennosotros})` }}></div>}
        </div>
    );
}