import React, { Component } from 'react'
import MinimalProductDescription from './MinimalProductDescription'

export default class MinimalProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageSelect: null,
    }
    this.changeImageSelect = this.changeImageSelect.bind(this)
  }

  changeImageSelect(val) {
    this.setState({ imageSelect: val })
  }
  productImage(body) {
    return this.state.imageSelect === null
      ? body.imagenproducto
        ? body.imagenproducto
        : ''
      : this.state.imageSelect.url
  }

  render() {
    return (
      <div className='container z-10 flex flex-col items-center justify-center h-auto gap-2 pt-32 md:mt-4 md:my-4 md:flex-row md:items-start'>
        <div className='flex w-full transition-all bg-gray-100 md:w-8/12'>
          <div className='flex justify-center w-full transition-all border border-gray-200 aspect-h-16 md:aspect-h-9 aspect-w-16 '>
            <img
              className='object-contain'
              src={this.productImage(this.props.data)}
              alt=''
            />
          </div>
        </div>
        <div className='flex w-full auto md:w-4/12'>
          <MinimalProductDescription
            estilo='minimal'
            id={this.props.data.id}
            image={this.props.data.image}
            extras={this.props.data.extras}
            variables={this.props.data.variables}
            imagenproducto={this.props.data.imagenproducto}
            nombreproducto={this.props.data.nombreproducto}
            categoriaproducto={this.props.data.categorias}
            precio={this.props.data.precio}
            detalladoproducto={this.props.data.detalladoproducto}
            stock={this.props.data.stock}
            categorias={this.props.data.categorias}
            changeImage={this.changeImageSelect}
          />
        </div>
      </div>
    )
  }
}
