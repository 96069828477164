import React, { useContext } from "react"
import { StoreContext } from "../Context/StoreContext"
import DeliciousCategoryHeader from "../Components/Delicious/DeliciousCategoryHeader"
import MinimalCategoryHeader from "../Components/Minimal/MinimalCategoryHeader"
import ServiceCategoryHeader from "../Components/Service/ServiceCategoryHeader"

export default function GetCategoryHeader({ data }) {
    const { store, setStore } = useContext(StoreContext)

     if (store.theme === "minimal") { return (<MinimalCategoryHeader category={data.nombrecategoria} length={data.productos.length} />) }
     if (store.theme === "delicious") { return (<DeliciousCategoryHeader category={data.nombrecategoria} length={data.productos.length} />) }
     if (store.theme === "service") { return (<ServiceCategoryHeader category={data.nombrecategoria} length={data.productos.length} />) }
}