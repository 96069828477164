import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

export default function MinimalContact({ data, textContact }) {

  return (
    <div className="relative flex flex-col min-h-screen font-serif">
      <div className="relative top-0 bottom-0 right-0 flex w-full bg-cover md:w-1/2 lg:w-2/3 h-96 md:h-screen md:absolute md:flex -z-1" style={{ backgroundImage: `url(${data.primeraimagennosotros})` }}></div>
      <div className="container p-4 pt-4 sm:px-0 md:pt-16">
        <div className="flex flex-col justify-start h-auto lg:flex-row">
          <div className="flex flex-col justify-center flex-grow lg:pt-12">
            <div className="items-center mb-8 text-lg font-semibold tracking-tighter text-black">{textContact.contactUs}</div>
            <div className="flex flex-col mb-4 tracking-tight">
              <p>{textContact.email} <span className="font-bold">{data.correoelectronico}</span> </p>
              <p>{textContact.phone} <span className="font-bold">{data.telefono}</span> </p>
            </div>
            <div className="flex flex-col tracking-tight">
              <p className="font-semibold tracking-tighter text-black">O síguenos en nuestras redes:</p>
              <a href={data ? data.socialmedia.facebook : '#'} target="_blank" rel="noreferrer" className="flex items-center gap-4 text-base font-light"><FontAwesomeIcon icon={faFacebookF} /> Facebook</a>
              <a href={data ? data.socialmedia.twitter : '#'} target="_blank" rel="noreferrer" className="flex items-center gap-4 text-base font-light"><FontAwesomeIcon icon={faTwitter} />Twitter</a>
              <a href={data ? data.socialmedia.instagram : '#'} target="_blank" rel="noreferrer" className="flex items-center gap-4 text-base font-light"><FontAwesomeIcon icon={faInstagram} />Instagram</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}