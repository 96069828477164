import axios from 'axios'

export const GetCuponsByDomainAPI = async (idtienda) => {
  try {
    const { data } = await axios.get(
      `https://ventafazil-strapi-production.up.railway.app/cupons?cliente=${idtienda}`
    )
    if (data) {
      return data
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export function CleanCupon() {
  localStorage.setItem('coupon', null)
  localStorage.setItem('freeshipping', false)

  window.location.reload(false) //BUGFIX
}

export const CompleteCupon = async () => {
  const activeCupon = JSON.parse(localStorage.getItem('coupon'))
  const cuponId = activeCupon.id
  const cuponStock = activeCupon.stock

  var body = {
    stock: cuponStock - 1,
  }

  try {
    const { data } = await axios.put(
      `https://ventafazil-strapi-production.up.railway.app/cupons/${cuponId}`,
      body
    )
    if (data) {
      localStorage.setItem('coupon', null)
      CleanCupon()

      return data
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

const PrototypeBody = {
  string: '123CUPON',
}
