import React from 'react'
import DeliciousProductCard from './DeliciousProductCard'

export default function DeliciousGridXL({
  data,
  nameCategory,
  category,
  categorias,
}) {
  return (
    <div className='container'>
      <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-6xl mx-auto grid-cols-1-xs justify-items-center items-center'>
        {data.map((product, index) => {
          return (
            <DeliciousProductCard
              md
              rounded
              overlay
              key={index}
              categorias={categorias}
              id={product._id}
              imagenproducto={product.imagenproducto}
              image={product.image}
              name={product.nombreproducto}
              price={
                product.precio ||
                (product?.productvariations?.length >= 1 &&
                  product.productvariations[0].precio)
              }
              codigoproducto={product.codigoproducto}
              variations={product.variations}
              stock={product.stock}
              isproductstock={product.isproductstock}
            />
          )
        })}
      </div>
    </div>
  )
}
