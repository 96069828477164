import { useContext, useState, useEffect, useRef } from 'react'
import { StoreContext } from '../../Context/StoreContext'
import { getCategoriesAPI } from '../../Utils/GetCategories'

export default function DeliciousCategories() {
  const { store } = useContext(StoreContext)
  const [categories, setCategories] = useState([])
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = (event) => {
    setScrollPosition(event.target.scrollLeft)
  }

  useEffect(() => {
    getCategoriesAPI(store.id).then((response) => {
      setCategories(response)
    }) //GET CATEGORIES
  }, [store.id])

  const categoriesContainerRef = useRef(null)
  return (
    <div className='relative'>
      <div className='absolute top-0 bg left-2  md:left-6 h-full w-6   flex items-center justify-center z-10 '>
        <div className='bg-black w-6 h-6 text-center rounded-md'>
          <button
            className='text-white '
            onClick={() => {
              categoriesContainerRef.current.scrollBy({
                left: -200,
                behavior: 'smooth',
              })
            }}
          >
            {'<'}
          </button>
        </div>
      </div>
      <div className='absolute top-0  md:right-6 right-2 h-full w-6 flex items-center justify-center z-10'>
        <div className='bg-black w-6 h-6 text-center rounded-md'>
          <button
            className='text-white'
            onClick={() => {
              categoriesContainerRef.current.scrollBy({
                left: 200,
                behavior: 'smooth',
              })
            }}
          >
            {'>'}
          </button>
        </div>
      </div>
      <div
        className='flex flex-row gap-2 px-1  overflow-x-hidden container w-10/12 md:w-11/12  lg:w-12/12 '
        onScroll={handleScroll}
        ref={categoriesContainerRef}
        style={{ scrollBehavior: 'smooth', scrollSnapType: 'x mandatory' }}
      >
        <div
          className={`container flex flex-row gap-2 ${
            categories.length <= 7 ? 'justify-center' : ''
          }`}
        >
          {categories.map((category, index) => (
            <a
              key={index}
              href={`/category/${category.id}`}
              className='flex shrink-0 items-center gap-2 px-2   py-2 bg-gray-200 rounded-full hover:bg-yellow-300 mx-auto  '
              style={{ textAlign: 'center' }}
            >
              <span className='flex w-full text-lg font-thin Ztracking-tighter justify-center p-1   text-black'>
                {category.nombrecategoria}
              </span>
            </a>
          ))}

          {/* after:transition-all after:opacity-0 after:-mr-2 hover:after:mr-0
          hover:after:opacity-100  after:text-white after:content-[">"]
          transition-all */}
        </div>
      </div>
    </div>
  )
}
