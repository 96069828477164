import React from 'react'
import GetFooter from '../Hooks/GetFooter'
import GetContact from '../Hooks/GetContact'

function Contacto() {
  return (
    // <div className='flex flex-col'>
    //   <GetContact />
    //   <GetFooter />
    // </div>
    <>
      <div className='flex flex-col  px-2 pt-5'>
        <GetContact />
      </div>
      <GetFooter />
    </>
  )
}

export default Contacto
