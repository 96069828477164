import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import GetFooter from '../Hooks/GetFooter'
import GetStore from '../Hooks/GetStore'
import GetCategories from '../Hooks/GetCategories'

export default function SearchResults() {
  return (
    <div>
      <GetCategories />
      <div className='container mt-12 mb-24 '>
        <div className='flex flex-row items-end gap-2 my-8'>
          <div className=''>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className='text-4xl text-black'
            />
          </div>
          <div className='grid grid-cols-1 '>
            <div className='text-xl font-light'>Search Results for</div>
            <div className='text-6xl font-bold'>Burger</div>
          </div>
        </div>

        <GetStore />
      </div>

      <GetFooter />
    </div>
  )
}
