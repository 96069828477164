import React, { useContext } from 'react'
import { StoreContext } from '../Context/StoreContext'
import DeliciousTyc from '../Components/Delicious/DeliciousTyc'

export default function GetTyC({ data }) {
  const { store, setStore } = useContext(StoreContext)

  if (store.theme === 'delicious') {
    return <DeliciousTyc data={store} />
  }
}
