import React, { useContext } from "react"
import { StoreContext } from "../Context/StoreContext"
import { LanguageContext } from "../Context/LanguageContext"
import MinimalGridXL from "../Components/Minimal/MinimalGridXL"
import MinimalGridSpecialCategory from "../Components/Minimal/MinimalGridSpecialCategory"
import DeliciousGridXL from "../Components/Delicious/DeliciousGridXL"
import DeliciusGridSpecialCategory from "../Components/Delicious/DeliciusGridSpecialCategory"
import ServiceGridXL from "../Components/Service/ServiceGridXL"

export default function GetPromotions({ data }) {
    const { store, setStore } = useContext(StoreContext)
    const language = useContext(LanguageContext)
    const textCategory = language.text.trendingProduct

    if (store.theme === "minimal") { return (<MinimalGridSpecialCategory data={data} nameCategory={textCategory}/>) }
    if (store.theme === "delicious") { return (<DeliciusGridSpecialCategory data={data} nameCategory={textCategory} />) }
    if (store.theme === "service") { return (<ServiceGridXL data={data} />) }
}