import React from 'react'
import { StoreContext } from '../Context/StoreContext'
import { getProductAPI } from '../Utils/GetProduct'
import { getCategoriesAPI } from '../Utils/GetCategories'
import { getProductsAPI } from '../Utils/GetProducts'
import GetStore from '../Hooks/GetStore'
import GetFooter from '../Hooks/GetFooter'
import GetProduct from '../Hooks/GetProduct'
import GetCategories from '../Hooks/GetCategories'

export default class Producto extends React.Component {
  static contextType = StoreContext

  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      producto: [],
      loaded: false,
      imageSelect: null,
      products: [],
      productsNoCategory: [],
    }
    this.changeImageSelect = this.changeImageSelect.bind(this)
  }

  componentDidMount() {
    getProductAPI(this.props.match.params.productid).then((response) => {
      this.setState({ producto: response })
      this.setState({ loaded: true })
    }) //GEt PRODUCT
    getProductsAPI(this.context.store.id).then((response) => {
      this.setState({ products: response })
      let _productsNoCategory = response.filter(
        (el) => el.categorias.length == 0
      )

      this.setState({ productsNoCategory: _productsNoCategory })
      // console.log("getProductsAPI",response)
    }) //GEt PRODUCTS
    getCategoriesAPI(this.context.store.id).then((response) => {
      this.setState({ categories: response })
      let _productos = this.state.productsNoCategory
      let _newCategorie = this.state.categories
      _newCategorie.push({
        nombrecategoria: 'Otros Productos',
        id: this.state.productsNoCategory.length.toString(),
        productos: _productos,
      })
      let newCategorie = _newCategorie
      this.setState({ categories: newCategorie })
      // console.log(response)
    }) //GET CATEGORIES
  }

  changeImageSelect(val) {
    this.setState({ imageSelect: val })
  }
  productImage(body) {
    return this.state.imageSelect === null
      ? body.image
        ? body.image[0].url
        : ''
      : this.state.imageSelect.url
  }

  render() {
    return (
      <div>
        <GetCategories />
        {this.state.loaded ? (
          <div className='relative '>
            <GetProduct data={this.state.producto} />
            <div className='px-4 md:px-0 py-8'>
              <GetStore
                custom='p-4'
                data={this.state.categories}
                unique={true}
                cat={this.state.producto.categorias[0]}
              />
            </div>
            <GetFooter />
          </div>
        ) : null}
      </div>
    )
  }
}
