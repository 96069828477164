import React from 'react'
import './index.css'
// import App from './App';
import ReactDOM from 'react-dom'
import AppNavigation from './Navigation/AppNavigation'
import { CartContextProvider } from './Context/CartContext'
import {
  SessionContext,
  SessionContextProvider,
} from './Context/SessionContext'
import { StoreContextProvider } from './Context/StoreContext'
import { LanguageProvider } from './Context/LanguageContext'
import { CurrencyProvider } from './Context/CurrencyContext'
import { CouponProvider } from './Context/CouponContext'

/* import { hydrate, render } from "react-dom"; */

ReactDOM.render(
  <React.StrictMode>
    <StoreContextProvider>
      <SessionContextProvider>
        <CartContextProvider>
          <LanguageProvider>
            <CurrencyProvider>
              <CouponProvider>
                <AppNavigation />
              </CouponProvider>
            </CurrencyProvider>
          </LanguageProvider>
        </CartContextProvider>
      </SessionContextProvider>
    </StoreContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

/* const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
} */
