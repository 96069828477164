import React, { useContext, useRef } from "react";
import 'react-slideshow-image/dist/styles.css';
import './ServiceSlider.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { StoreContext } from "../../Context/StoreContext";

import { transformCategory } from "../../Utils/utils";

export default function ServiceSlider({ data }) {
    const { store, setStore } = useContext(StoreContext)

    return (
        <div>
            <div className="flex flex-row w-full p-0 m-0 overflow-x-auto snap-x snap-mandatory no-scrollbar">
                {data.map((slider, i) => {
                    return (
                        <div key={i} style={{ backgroundImage: `url(${slider.image && slider.image.length > 0 ? slider.image[0].url : ''})` }}
                            className={`flex snap-center w-screen bg-fixed h-screen flex-col bg-gray-200  shrink-0 justify-center relative bg-center md:h-[70vh] bg-cover`}>
                            <div className="container flex flex-col items-start justify-center gap-2">
                                <div className="font-sans text-5xl font-semibold tracking-tighter text-black capitalize leading-tighter">{slider.nombreproducto}</div>
                                <Link to={`/producto/${slider.id}`} className="z-10 flex  hover:text-black flex-row items-center gap-2 p-3 py-2 font-sans text-lg font-bold tracking-tight text-black transition-all duration-700 transform hover:border-black  border border-white rounded-lg hover:-translate-y-0.5 hover:translate-x-0.5 hover:shadow-[-5px_5px_0px_0px_rgba(0,0,0,1)]">
                                    <span className="flex flex-row items-center gap-2 text-lg ">
                                        <span className="text-sm font-bold">S/ </span>
                                        <span>{Number(slider.precio).toFixed(2)}</span>
                                        <FontAwesomeIcon icon={faShoppingCart} className="text-lg " />
                                    </span>
                                </Link>
                            </div>
                            <div className="absolute top-0 bottom-0 left-0 right-0 flex w-full h-full opacity-100 from-transparent bg-gradient-to-tl to-black"></div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}