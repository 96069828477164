import React from 'react'
import { StoreContext } from '../../Context/StoreContext'
import GetFooter from '../../Hooks/GetFooter'
import GetCategories from '../../Hooks/GetCategories'
import { getCategoriesAPI } from '../../Utils/GetCategories'
import GetCategoriesGrid from '../../Hooks/GetCategoriesGrid'
import GetStore from '../../Hooks/GetStore'

export default class CategoriesSecondv extends React.Component {
  static contextType = StoreContext

  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      loaded: false,
    }
  }

  componentDidMount() {
    getCategoriesAPI(this.context.store.id).then((response) => {
      this.setState({ categories: response })
      this.setState({ loaded: true })
    }) //GET CATEGORIES
  }

  render() {
    return (
      <>
        {this.state.loaded ? (
          <>
            <div className='px-4'>
              <GetCategories />
              <div className='pt-24'>
                <GetStore data={this.state.categories} />
              </div>
            </div>
            <GetFooter />
          </>
        ) : null}
      </>
    )
  }
}
