import axios from 'axios'

export const GetComplaintsBook = async (
  reclamo,
  nombre = 'nombre',
  apellido = 'apellido',
  tipodedocumento = 'tipodedocumento',
  documentoid = 'documentoid',
  correo = 'correo',
  telefono = 'telefono',

  detalle1 = 'detalle1',

  detalleRQ = 'detalleRQ',

  detalleAccion = 'detalleAccion',
  storemail = '',
  selectedOption = 'selectedOption',
  selectedOption1 = 'selectedOption1'
) => {
  try {
    const { data } = await axios.get(
      `https://pagos.ventafacil.app/complaintsbook?mail=${correo}&&nombre=${nombre}&&apellido=${apellido}&&storemail=${storemail}&&tipodedocumento=${tipodedocumento}&&documentoid=${documentoid}&&detalle1=${detalle1}&&telefono=${telefono}&&reclamo=${reclamo}&&detalleRQ=${detalleRQ}&&detalleAccion=${detalleAccion}&&selectedOption=${selectedOption} &&selectedOption1=${selectedOption1}`
    )
    return data
  } catch (error) {
    console.log(error)
  }
}
