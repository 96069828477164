import React, { createContext, useContext, useReducer, useEffect } from 'react'
import CouponReducer from './reducer/CouponReducer'
import { GetCuponsByDomainAPI } from '../Utils/GetCuponsByDomain'
import { StoreContext } from './StoreContext'
import { SessionContext } from './SessionContext'

const initialState = {
  coupons: [],
  discount: {},
  newTotal: JSON.parse(localStorage.getItem('newTotal')) || 0,
  couponsLoading: false,
  valid: false,
  couponSelect: JSON.parse(localStorage.getItem('coupon')) || {},
  collected: false,
  isHomeDelivery: JSON.parse(localStorage.getItem('user'))?.ishomedelivery,
  ispickupstore: JSON.parse(localStorage.getItem('user'))?.ispickupstore,
  isPricePerZone: JSON.parse(localStorage.getItem('user'))?.iszoneprice,
  isOnlyPrices: JSON.parse(localStorage.getItem('user'))?.isonlyprice,
  isactivePlatform: JSON.parse(localStorage.getItem('user'))?.activeizi,
  isactiveWsp: JSON.parse(localStorage.getItem('user'))?.activewhatsapp,
}

export const CouponContext = createContext()
let cupon = JSON.parse(localStorage.getItem('coupon'))

export const CouponProvider = ({ children }) => {
  const { store } = useContext(StoreContext)
  const { session, setSession } = useContext(SessionContext)

  const [state, dispatch] = useReducer(CouponReducer, initialState)

  const fetchCoupons = async () => {
    dispatch({ type: 'GET_CUPONS_BEGIN' })
    try {
      if (store.id) {
        const data = await GetCuponsByDomainAPI(store.id)
        dispatch({ type: 'GET_CUPONS_SUCCESS', payload: data })
      }
    } catch (error) {
      dispatch({ type: 'GET_PRODUCTS_ERROR' })

      console.error('Error fetching cupons:', error)
    }
  }

  useEffect(() => {
    fetchCoupons()
    const isHomeDelivery = store.ishomedelivery
    const ispickupstore = store.ispickupstore
    const isPricePerZone = store.iszoneprice
    const isOnlyPrices = store.isonlyprice

    const isactivePlatform = store.activeizi
    const isactiveWsp = store.activewhatsapp

    dispatch({
      type: 'SET_ACCOUNT',
      payload: {
        isHomeDelivery: isHomeDelivery,
        ispickupstore: ispickupstore,
        isPricePerZone,
        isOnlyPrices,
        isactivePlatform,
        isactiveWsp,
      },
    })
  }, [store.id])

  const changeHandler = (e) => {
    const intent = state.coupons.find(
      (coupon) => coupon.name === e.target.value
    )

    if (intent) {
      const startdate = new Date(intent.startdate)
      const enddate = new Date(intent.enddate)
      const currentdate = new Date()

      if (intent.stock >= 1) {
        if (currentdate >= startdate && currentdate <= enddate) {
          dispatch({
            type: 'SET_VALID',
            payload: { valid: true, discount: intent },
          })
        } else {
          dispatch({
            type: 'SET_VALID',
            payload: { valid: false, discount: {} },
          })
        }
      } else {
        dispatch({
          type: 'SET_VALID',
          payload: { valid: false, discount: {} },
        })
      }
    } else {
      dispatch({
        type: 'SET_VALID',
        payload: { valid: false, discount: {} },
      })
    }
  }

  let totalCarritoDiscount

  const submitHandler = (e) => {
    localStorage.setItem('coupon', JSON.stringify(state.couponSelect))

    dispatch({
      type: 'SET_COLLECTECD',
      payload: { collected: true },
    })
  }

  //*EFFECT
  //! FFF
  useEffect(() => {
    totalCarritoDiscount = 0

    if (state.couponSelect.applyto === 'wholecart') {
      let totalCarts = () => {
        const maprr = session.map((x) => {
          const amount = x.amount || 1
          return x.precio * amount
        })
        const total = maprr.reduce((a, b) => Number(a) + Number(b), 0)

        return total.toFixed(2)
      }
      let totall1
      // El cupón se aplica al carrito completo
      if (state.couponSelect.benefit === 'percentage') {
        if (totalCarts() === 0) {
          totall1 = totalCarts()
        } else {
          const rawTotal = (
            totalCarts() -
            (totalCarts() * state.couponSelect.value) / 100
          ).toFixed(2)

          totall1 = rawTotal
        }

        // setNewTotal((total - (total * discount.value) / 100).toFixed(2))
      } else if (state.couponSelect.benefit === 'discount') {
        if (totalCarts() === 0) {
          totall1 = totalCarts()
        } else {
          totall1 = (totalCarts() - state.couponSelect.value).toFixed(2)
        }
      } else if (state.couponSelect.benefit === 'freeshipping') {
        totall1 = totalCarts()
        localStorage.setItem('freeshipping', true)

        // setNewTotal((total - discount.value).toFixed(2))
      }

      dispatch({
        type: 'SET_PRICE',
        payload: { totall1 },
      })

      localStorage.setItem('newTotal', totall1)
    } else if (state.couponSelect.applyto === 'specificproduct') {
      const productIds = state.couponSelect.productos.map(
        (producto) => producto._id
      )

      const discountedProducts = session.filter((producto) =>
        productIds.includes(producto.id)
      )
      const nonDiscountedProducts = session.filter(
        (producto) => !productIds.includes(producto.id)
      )

      let totalNoDiscountedPrice = () => {
        const maprr = nonDiscountedProducts.map((x) => {
          const amount = x.amount || 1
          return x.precio * amount
        })
        return Number(maprr.reduce((a, b) => Number(a) + Number(b), 0))
      }

      discountedProducts.forEach((producto) => {
        if (state.couponSelect.benefit === 'percentage') {
          // Aplicar descuento porcentaje al producto
          const discountedPrice =
            producto.amount *
            (producto.precio -
              (producto.precio * state.couponSelect.value) / 100)

          totalCarritoDiscount = totalCarritoDiscount + discountedPrice

          // Actualizar el precio del producto en el carrito
          // producto.precio = discountedPrice;
        } else if (state.couponSelect.benefit === 'discount') {
          // Aplicar descuento fijo al producto

          const discountedPrice =
            producto.amount * (producto.precio - state.couponSelect.value)

          totalCarritoDiscount = totalCarritoDiscount + discountedPrice

          // const total = sumTotal() - totalCarritoDiscount
          // console.log('totalll111', total)
        } else if (state.couponSelect.benefit === 'freeshipping') {
          const discountedPrice =
            producto.amount * (producto.precio - state.couponSelect.value)
          totalCarritoDiscount = totalCarritoDiscount + discountedPrice

          if (totalNoDiscountedPrice() == 0) {
            localStorage.setItem('freeshipping', true)
          } else {
            localStorage.setItem('freeshipping', false)
          }
        }
      })

      localStorage.setItem('coupon', JSON.stringify(state.couponSelect))

      const totall1 = (totalCarritoDiscount + totalNoDiscountedPrice()).toFixed(
        2
      )
      dispatch({
        type: 'SET_PRICE',
        payload: { totall1 },
      })
      localStorage.setItem('newTotal', totall1)
    }
    // Resto del código...
    else if (state.couponSelect.applyto === 'specificcategory') {
      // El cupón se aplica a categorías específicas
      const categoryIds = state.couponSelect.categorias.map(
        (categoria) => categoria._id
      )

      console.log('intent', '647f4a032643540017327f52')
      const discountedCategory = session.filter((category) => {
        // console.log('intent111', category.categorias[0].id)
        return categoryIds.includes(category?.categorias[0]?._id)
      })

      const nonDiscountedCategory = session.filter(
        (category) => !categoryIds.includes(category?.categorias[0]?._id)
      )
      let totalNoDiscountedPrice = () => {
        const maprr = nonDiscountedCategory.map((x) => {
          const amount = x.amount || 1
          return x.precio * amount
        })
        return Number(maprr.reduce((a, b) => Number(a) + Number(b), 0))
      }

      // Verificar si la categoría del producto actual está en la lista de categorías del cupón
      discountedCategory.forEach((producto) => {
        if (state.couponSelect.benefit === 'percentage') {
          // Aplicar descuento porcentaje al producto
          const discountedPrice =
            producto.amount *
            (producto.precio -
              (producto.precio * state.couponSelect.value) / 100)

          totalCarritoDiscount = totalCarritoDiscount + discountedPrice

          // Actualizar el precio del producto en el carrito
          // producto.precio = discountedPrice;
        } else if (state.couponSelect.benefit === 'discount') {
          // Aplicar descuento fijo al producto

          const discountedPrice =
            producto.amount * (producto.precio - state.couponSelect.value)

          totalCarritoDiscount = totalCarritoDiscount + discountedPrice

          // const total = sumTotal() - totalCarritoDiscount
        } else if (state.couponSelect.benefit === 'freeshipping') {
          // console.log('hollaaa')
          const discountedPrice =
            producto.amount * (producto.precio - state.couponSelect.value)
          totalCarritoDiscount = totalCarritoDiscount + discountedPrice

          if (totalNoDiscountedPrice() == 0) {
            localStorage.setItem('freeshipping', true)
          } else {
            localStorage.setItem('freeshipping', false)
          }
        }
      })
      localStorage.setItem('coupon', JSON.stringify(state.couponSelect))

      const totall1 = (totalCarritoDiscount + totalNoDiscountedPrice()).toFixed(
        2
      )
      dispatch({
        type: 'SET_PRICE',
        payload: { totall1 },
      })
      localStorage.setItem('newTotal', totall1)
    } else {
      //**else */
      const maprr = session.map((x) => {
        const amount = x.amount || 1
        return x.precio * amount
      })

      const totall1 = Number(
        maprr.reduce((a, b) => Number(a) + Number(b), 0)
      ).toFixed(2)
      dispatch({
        type: 'SET_PRICE',
        payload: { totall1 },
      })

      localStorage.setItem('newTotal', totall1)
    }
  }, [session, cupon, state.collected])

  return (
    <CouponContext.Provider value={{ state, changeHandler, submitHandler }}>
      {children}
    </CouponContext.Provider>
  )
}

export const useCouponContext = () => {
  return useContext(CouponContext)
}
