import axios from 'axios'
import { useState } from 'react'
import { getProductAPI } from './GetProduct'
import Swal from 'sweetalert2'

export const verifyStock = async () => {
  const stock = JSON.parse(localStorage.getItem('session'))

  // const cuponId = stock.id
  // const cuponStock = stock.amount
  let data = []

  for (let i = 0; i < stock.length; i++) {
    const x = stock[i]

    // stock.forEach(async (x, i) => {
    const productid = x.id

    const res = await getProductAPI(productid)

    // getProductAPI(productid).then((res) => {

    if (x.variation === null || x.variation === undefined) {
      if (parseInt(res.stock) < x.amount) {
        data.push(x.nombreproducto)
      }
    } else if (Object.entries(x.variation).length === 0) {
      if (parseInt(res.stock) < x.amount) {
        data.push(x.nombreproducto)
      }
    } else {
      const resp = res.productvariations.map((item, index) => {
        if (JSON.stringify(item.options) === JSON.stringify(x.variation)) {
          const newNombre =
            x.nombreproducto + ' ' + Object.values(x.variation).join('/')
          let newStock = x.max - x.amount

          if (parseInt(x.max) < x.amount) {
            data.push(newNombre)
          }
        } else {
          return item
        }
      })
    }
  }

  // await new Promise((resolve) => setTimeout(resolve, 2500))

  return data
}
