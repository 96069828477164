import React, { Component } from "react";
import ServiceProductDescription from "./ServiceProductDescription";

export default class ServiceProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSelect: null
        };
        this.changeImageSelect = this.changeImageSelect.bind(this);
    }

    changeImageSelect(val) { 
        this.setState({ imageSelect: val })
    }
    
    productImage(body) {
        return this.state.imageSelect === null ? body.image ? body.image[0].url : '' : this.state.imageSelect.url
    }


    render() {
        return (
            <div className="relative pt-24 bg-black " >
                <div className="flex mb-4">
                    <div className="container z-10 flex flex-col items-center justify-center h-auto gap-4 py-6 md:mt-4 md:my-4 md:flex-row md:items-start">
                        <div className="flex w-full h-full max-h-full md:w-8/12">
                            <div className="flex w-full h-full bg-center bg-cover ">
                                <img className="rounded-xl h-inherit" src={this.productImage(this.props.data)} alt="" />
                            </div>
                        </div>
                        <div className="flex w-full auto md:w-4/12">
                            <ServiceProductDescription
                                id={this.props.data.id}
                                image={this.props.data.image}
                                extras={this.props.data.extras}
                                variables={this.props.data.variables}
                                imagenproducto={this.props.data.imagenproducto}
                                nombreproducto={this.props.data.nombreproducto}
                                categoriaproducto={this.props.data.categorias}
                                precio={this.props.data.precio}
                                detalladoproducto={this.props.data.detalladoproducto}
                                stock={this.props.data.stock}
                                categorias={this.props.data.categorias}
                                changeImage={this.changeImageSelect}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}