
import React, { createContext, useState, useMemo } from "react";
const CartContext = createContext({
    cart: [],
    setCart: () => { },
});

const CartContextProvider = ({ children }) => {
    const [cart, setCart] = useState(false);
    const value = useMemo(() => ({ cart, setCart }), [cart]);

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    );
};

const CartContextConsumer = CartContext.Consumer;
export { CartContext, CartContextProvider, CartContextConsumer };