const UICheckbox = ({
  label,
  initialValue = false,
  disabled,
  className,
  handleChange,
  value,
  handleOptionChange,
  selectedOption,
  option,
  name,
}) => {
  return (
    <div
      className={`flex items-center gap-4 h-[40px] ${className} ${
        disabled && 'cursor-not-allowed'
      }`}
    >
      <input
        type='radio'
        name={name}
        value={value}
        disabled={disabled}
        checked={selectedOption === option}
        onChange={handleOptionChange}
        className='mr-2'
      />
      <label
        className={`shrink-0 text-xs  ${
          disabled && 'cursor-not-allowed  text-slate-400'
        }`}
      >
        {label}
      </label>
    </div>
  )
}

export default UICheckbox
