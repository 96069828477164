export default function ServiceCategoryHeader({ category, length, previo,data }) {
    return (
        <div className='container p-4 pt-24'>
            <div className='flex flex-col items-center gap-2 my-8'>
                <div className='text-4xl font-bold tracking-tighter '>{category}</div>
                {length > 0 ?
                    (<div className='font-semibold'>{length} "productos"</div>) :
                    ("no hay productos")
                }
            </div>
        </div>
    );
}