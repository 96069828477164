import React, { createContext, useState, useMemo, useEffect } from 'react'
import axios from 'axios'
const UserContext = createContext({
  user: {},
  setUser: () => {},
})

// let datauser = JSON.parse(localStorage.getItem('user'))
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const value = useMemo(() => ({ user, setUser }), [user])
  useEffect(() => {
    getuser('627a770b2c35fb0017422cdd')
  }, [])
  let getuser = (user) => {
    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }
    axios(config)
      .then((response) => {
        setUser(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const UserContextConsumer = UserContext.Consumer
export { UserContext, UserContextProvider, UserContextConsumer }
