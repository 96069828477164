import React, { useContext } from 'react'
import { StoreContext } from '../Context/StoreContext'
import DeliciousCategories from '../Components/Delicious/DeliciousCategories'
import MinimalCategories from '../Components/Minimal/MinimalCategories'
import ServiceCategories from '../Components/Service/ServiceCategories'

export default function GetCategories(props) {
  const { store, setStore } = useContext(StoreContext)

  if (store.theme === 'minimal') {
    return <MinimalCategories routes={props.routes} />
  }
  if (store.theme === 'delicious') {
    return <DeliciousCategories routes={props.routes} />
  }
  if (store.theme === 'service') {
    return <ServiceCategories routes={props.routes} />
  }
}
