import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faSpinner, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { SessionContext } from '../../Context/SessionContext'
import { CurrencyContext } from '../../Context/CurrencyContext'
const ButtonPayment = ({
  stepper,
  textFinishBuy,
  userPais,
  loading,
  envioStep1,
  envioStep2,
  textCar,
  activeFormButton,
  _metodoPago,
  phoneWsp,
}) => {
  const mensajePersonalizado1 = encodeURIComponent(
    'HOLA MOTT,\nESTO INTE\nDDASASDASDAWWA'
  )
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  let mensajeWsp = 'Hola, quiero realizar un pedido de : %0A'
  const { session, setSession } = useContext(SessionContext)
  const texto = session.map(
    (item) =>
      `${item.nombreproducto} - ${item.amount} unid - ${currency} ${item.precio} %0A`
  )
  const mensajePersonalizado = mensajeWsp + ' ' + texto.join(', ')
  return (
    <>
      <div>
        <div id='myPaymentForm' className=''>
          {stepper === 0 ? (
            <>
              <button
                className={`flex justify-center w-full p-2 px-3 text-lg font-bold text-center transition-all ${
                  loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-yellow-400'
                } rounded-md shadow-black hover:shadow hover:-translate-y-1 text-md `}
                onClick={envioStep1}
                disabled={loading}
              >
                {textCar.finishBuy.finishBuy}
              </button>
              {loading && (
                <div className='flex justify-center items-center w-full h-full absolute'>
                  <span>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className='spinner-border animate-spin rounded-full font-bold text-8xl text-blue-500 '
                    />
                  </span>
                </div>
              )}
            </>
          ) : (
            <>
              {activeFormButton ? (
                _metodoPago.value ? (
                  <div
                    onClick={envioStep2}
                    className={`self-end py-2 mx-4 text-white rounded-md cursor-pointer text-semibold ${
                      loading ? 'bg-gray-500' : 'bg-amber-500'
                    } px-14`}
                    disabled={loading}
                  >
                    {textFinishBuy.finishOrder}
                  </div>
                ) : (
                  <a
                    className='flex items-center gap-2 p-4 px-6 text-white transition-all transform bg-pink-500 rounded opacity-75 hover:opacity-100 hover:shadow-xl hover:-translate-y-1'
                    href={`https://api.whatsapp.com/send?phone=${phoneWsp}&text=${mensajePersonalizado}`}
                    target='_blank'
                  >
                    <span>
                      <FontAwesomeIcon icon={faWhatsapp} className='text-xl' />
                    </span>
                    Pedir por WhatsApp
                  </a>
                )
              ) : (
                <div className='self-end py-2 mx-4 text-white rounded-md cursor-pointer text-semibold bg-amber-200 px-14'>
                  {textFinishBuy.finishOrder}
                </div>
              )}
            </>
          )}
        </div>
        {_metodoPago.value && (
          <div className='p-4 text-center text-xxs'>
            Secured by{' '}
            <span className='font-bold'>
              {userPais === '63e6a8f8e36e8e0018d9463c' ? 'Izipay' : 'Clip.Mx'}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default ButtonPayment
