
import { createContext, useState } from "react";
import esPE from "../Utils/Language/es-PE.json"
import esMX from "../Utils/Language/es-MX.json"
import enUS from "../Utils/Language/en-US.json"

const LanguageContext = createContext()

const LanguageProvider = ({ children }) => {
    const [text, setText] = useState(esPE)

    const changeLanguage = (lang) => {
        switch (lang) {
            case "es-EP":
                setText(esPE)
                break;
            case "es-MX":
                setText(esMX)
                break;
            case "en-US":
                setText(enUS)
                break;
            default:
                setText(esPE)
        }
    }

    const data = { text, changeLanguage }
    return (
        <LanguageContext.Provider value={data}>
            {children}
        </LanguageContext.Provider>
    )
}

export { LanguageContext, LanguageProvider }