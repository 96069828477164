import React from "react";
import MinimalProductCard from "./ServiceProductCard";

export default function ServiceProductsList({ products, name, id, index }) {
    return (
        <div className="flex flex-col w-full pb-4 mb-12" id={`ProductsContainer-${id}-${index}`} >
            <div id="gridProductLg" className="mb-2 font-serif text-lg font-bold tracking-tight text-black capitalize ">{name}</div>
            <div className="flex flex-col gap-6 py-4" >
                {products.length > 0 ? products.map((product, index) => {
                    return (
                        <MinimalProductCard md key={index} id={product.id} imagenproducto={product.imagenproducto} image={product.image} name={product.nombreproducto} price={product.precio} />
                    )
                }) : "no hay productos"}
            </div>
        </div>
    );
}