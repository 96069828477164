import React from 'react'
import { useState } from 'react'

const NavbarCheckout = ({ stepper, textFinishBuy, userPais }) => {
  let hidden = true
  switch (userPais) {
    // PERU
    case '63e6a8f8e36e8e0018d9463c':
      break

    // MEXICO
    case '63e6a912e36e8e0018d9463d':
      hidden = false
      break
    default:
      break
  }

  return (
    <div className='flex justify-between w-full max-w-sm mx-auto my-4  px-2 '>
      <div className='flex flex-col items-center'>
        <div
          className={`flex items-center justify-center w-8 h-8 font-bold text-white ${
            stepper === 0 ? 'bg-yellow-500' : 'bg-gray-400'
          } rounded-full shrink-0`}
        >
          1
        </div>
        <div className='text-xs'>{textFinishBuy.steps.step1}</div>
      </div>
      <div className='flex flex-col items-center'>
        <div
          className={`flex items-center justify-center w-8 h-8 font-bold text-white ${
            stepper === 1 ? 'bg-yellow-500' : 'bg-gray-400'
          } rounded-full shrink-0`}
        >
          2
        </div>
        <div className='text-xs'>{textFinishBuy.steps.step2}</div>
      </div>

      {hidden && (
        <div className='flex flex-col items-center'>
          <div
            className={`flex items-center justify-center w-8 h-8 font-bold text-white ${
              stepper === 2 ? 'bg-yellow-500' : 'bg-gray-400'
            } rounded-full shrink-0`}
          >
            3
          </div>
          <div className='text-xs'>{textFinishBuy.steps.step3}</div>
        </div>
      )}
    </div>
  )
}

export default NavbarCheckout
